// Source: https://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
$social-brand-facebook: #3b5998;
$social-brand-twitter: #55acee;
$social-brand-linkedin: #007bb5;
$about-the-author-text-transform: uppercase;
$about-the-author-separator-width: 5rem;
$about-the-author-separator-thickness: 0.125rem;

.about-the-author {
  background-color: $white;
  padding: 1rem;
  border: 1px solid $medium-gray;

  .separator-left {
	text-align: left;
	@include clearfix;

	&::after {
	  position: relative;
	  width: $about-the-author-separator-width;
	  border-bottom: $about-the-author-separator-thickness solid $primary-color;
	  margin: 0.3rem auto 0;
	  margin-left: 0;
	}
  }

  .author-title {
	text-transform: $about-the-author-text-transform;
  }

  .author-social {
	text-align: center;
	margin-top: 0.7rem;
	margin-bottom: 0.7rem;

	.fa-stack {
	  display: inline-block;

	  &.facebook {
		color: lighten($social-brand-facebook, 10%);

		&:hover,
		&:focus {
		  color: $social-brand-facebook;
		}
	  }

	  &.twitter {
		color: lighten($social-brand-twitter, 10%);

		&:hover,
		&:focus {
		  color: $social-brand-twitter;
		}
	  }

	  &.linkedin {
		color: lighten($social-brand-linkedin, 10%);

		&:hover,
		&:focus {
		  color: $social-brand-linkedin;
		}
	  }
	}
  }

  .author-image {
	border: 1px solid $medium-gray;
  }
}