.fullscreen-image-slider {
  .orbit-image {
	width: 100%;
	height: 80vh;
	-o-object-fit: cover;
	object-fit: cover;
  }

  .orbit-caption {
	bottom: 50%;
	transform: translateY(50%);
	background: none;
	text-align: center;
	color: $white;
	font-weight: bold;
	font-size: 1.2em;

	@include breakpoint(large) {
	  h1{
		font-size: 3em !important;
	  }
	}

	@include breakpoint(medium only) {
	  font-size: 1em;
	  h1{
		font-size: 2.4em !important;
	  }
  	}

	@include breakpoint(small only) {
	  font-size: 0.8em;
	  h1{
		font-size: 2em !important;

		&::before,
		&::after{
		  display: none !important;
		}
	  }
	}

	h1{
	  padding: 30px 0;

	  &::before,
	  &::after
	  {
		content: " ";
		display: block;
		position: absolute;
		top: auto;
		bottom: auto;
		width: 300px;
		height: 2px;
		background: white;
		left: 50%;
		margin-left: -150px;
	  }
	  &::before{ top: 50px; }
	  &::after{ bottom: 100px; }
	}
  }
}