.info-block-about{
  .block{
	background: white;
	padding: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
	@include border-radius(10px);
	@include shadow(0px 0 12px -4px rgba(0, 0, 0, 0.1));
	color: $color-dark-light;

	i{
	  font-size: 3em;
	}
  }
  .title{
	font-size: 1.2em;
	font-weight: 600;
  }
  .description{
	font-size: 1.4em;
	margin-bottom: 0;
  }
}