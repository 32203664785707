.socials{
  background: #fcfcfc;
  color: $color-dark-light;
  border-bottom: 1px solid $border-bottom-color;

  a{ color: $color-dark-light; }

  .menu{
    a{
      padding: 8px;
    }
  }

  @include breakpoint(medium only) {
    font-size: 0.9em;
  }
}

.top-bar{ padding: 0.5rem 0; }

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}

[data-sticky] {
  width:100%;
}

.sticky-container {
  z-index: 305;
  border-bottom: 1px solid $border-bottom-color;
  .padding-custom{ padding: 1px; }
  .title-bar{
    background: white;

    .title-bar-title{ color: $color-dark-light; }
    .menu-icon{
      color: $color-dark-light;
      margin-bottom: 4px;

      &::after{
        display: none;
      }
    }
  }
}

.btn-buy{
  margin-top: 1rem;
}

.sticky.is-stuck{
  background: $color-white;
  border-bottom: 1px solid $border-bottom-color;
}

.menu-mini-container{
  overflow: hidden;
}

.btn-menu-buy{
  position: absolute;
  right: 4px;
  top:5px;
  padding: 0.5em 1em;
  background: orange;
  color: white !important;
  @include border-rounded;
}



$menu-hover-lines-transition: all 0.35s ease;
$menu-hover-lines-border-width: 3px;

.menu-hover-lines {


  @include breakpoint(medium){
    li{
      position: relative;

      &:before,
      &::after {
        height: $menu-hover-lines-border-width;
        position: absolute;
        content: '';
        transition: $menu-hover-lines-transition;
        background-color: $primary-color;
        width: 0;
      }

      &::before {
        top: 0;
        left: 0;
      }

      &::after {
        bottom: 0;
        right: 0;
      }

      &:hover::before,
      .active a::before,
      &:hover::after,
      .active a::after {
        width: 100%;
      }
    }

    .is-dropdown-submenu{
      li{
        &:hover{
          background: #f9f9f9;
        }
        &:before,
        &::after {
          display: none;
        }
      }
    }
  }
}

/*.menu-hover-lines {
  text-align: center;
  letter-spacing: 1px;
  transition: $menu-hover-lines-transition;

  li a {
    padding: 0.75rem 0;
    color: rgba($body-font-color, 0.5);
    position: relative;
  }

  li:first-child a {
    margin-left: 0;
  }

  li.active > a {
    background-color: transparent;
  }

  a:before,
  a::after {
    height: $menu-hover-lines-border-width;
    position: absolute;
    content: '';
    transition: $menu-hover-lines-transition;
    background-color: $primary-color;
    width: 0;
  }

  a::before {
    top: 0;
    left: 0;
  }

  a::after {
    bottom: 0;
    right: 0;
  }



  a:hover::before,
  .active a::before,
  a:hover::after,
  .active a::after {
    width: 100%;
  }
}*/

