.testimonial-slider-container {
  background: $light-gray;
  padding: 1rem 1rem;

  .orbit-previous,
  .orbit-next {
    color: $black;

    &:hover, &:active, &:focus {
      background-color: transparent;
    }
  }

  .orbit-previous {
    left: -1rem;
  }

  .orbit-next {
    right: -1rem;
  }

  .testimonial-slide {
    display: flex;
    justify-content: center;
  }

  .testimonial-slide-content {
    padding: 1rem 1.5rem;

    @media screen and (min-width: 40em) {
      padding: 1rem 4rem;
    }
  }

  .profile-pic {
    max-width: 225px;
  }

  .testimonial-slide-text {
    padding-left: 3rem;
  }

  .testimonial-slide-quotation {
    font-size: 1.2rem;
    position: relative;

    &::before {
      content: "“";
      font-size: 6rem;
      color: lighten($black, 80%);
      position: absolute;
      top: -3rem;
      left: -3rem;
    }
  }

  .testimonial-slide-author-container {
    display: flex;

    @include breakpoint(small only) {
      .testimonial-slide-author-info {
        margin-left: 1rem;
      }
    }
  }
}