$testimonial-light-blue: rgba($primary-color, 0.25);
$testimonial-block-vertical-bg: $testimonial-light-blue;


.testimonial-block-vertical-quote {
  background: $testimonial-block-vertical-bg;
  padding: $global-padding;
  border-radius: $global-radius;
  margin-bottom: 1.875rem;
  position: relative;

  p {
	margin-bottom: 0;
  }

  &::after {
	content: '';
	bottom: -1.25rem;
	left: 2.25rem;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 1.25rem 1.125rem 0 1.125rem;
	border-color: $testimonial-block-vertical-bg transparent transparent transparent;
	position: absolute;
  }
}

.testimonial-block-vertical-person {
  display: flex;
  margin-left: 1.65rem;
  align-items: center;
}

.testimonial-block-vertical-avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.testimonial-block-vertical-name {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 0.65rem;
}

.testimonial-block-vertical-info {
  text-transform: uppercase;
  font-size: 14px;
  color: $dark-gray;
  margin-bottom: 0;
  margin-left: 0.65rem;
}

