$font-color: #fff;
$nav-background: #f9f9f9;


body {
  counter-reset: section;
}

.breadcrumb-counter-nav {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  list-style: none;
  background: $nav-background;

  .last{
	a{
	  color: #666 !important;
	}
  }

  .breadcrumb-counter-nav-item {
	position: relative;
	flex: 1 0 auto;
	padding: 1rem 2rem;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;

	&:hover {
	  background: #eee;
	  &:after,
	  &::after {
		border-left-color: #eee;
	  }
	}

	&:after,
	&::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  right: -1rem;
	  width: 0;
	  height: 0;
	  border-top: 1.75rem solid transparent;
	  border-bottom: 1.75rem solid transparent;
	  border-left: 1rem solid $nav-background;
	  z-index: 300;
	}

	a {
	  color: #333;
	  font-size: 0.875rem;
	}
  }

  @media only screen and (max-width: 52em) {
	flex-direction: column;
  }
}

