$article-title-case: uppercase;

$social-brand-facebook: #3b5998;
$social-brand-twitter: #55acee;
$social-brand-linkedin: #007bb5;
$social-brand-google-plus: #dd4b39;

.simple-article-header {
  background: $white;
  padding: 1.3rem 2rem .7rem;
  margin-bottom: 1rem;

  .article-date-read {
	color: $dark-gray;
  }

  .article-title {
	text-transform: $article-title-case;

	a {
	  color: $black;

	  &:hover,
	  &:focus {
		color: $primary-color;
	  }
	}
  }

  .article-author-comments {
	a.article-comments {
	  color: $dark-gray;

	  &:hover,
	  &:focus {
		color: $primary-color;
	  }
	}
  }

  .article-date-read,
  .article-title,
  .article-author-comments,
  .article-social,
  .article-post-image,
  .article-post-content {
	margin-bottom: 0.25rem;
  }

  .article-social {
	margin-top: 0.75rem;

	.social {
	  margin-right: 0.25rem;

	  &.facebook {
		@include button-style($social-brand-facebook, auto, $white);
	  }
	  &.twitter {
		@include button-style($social-brand-twitter, auto, $white);
	  }
	  &.linkedin {
		@include button-style($social-brand-linkedin, auto, $white);
	  }
	  &.google-plus {
		@include button-style($social-brand-google-plus, auto, $white);
	  }

	  > .fa {
		color: $white;

		&.fa-comments-o {
		  margin-right: 0.25rem;
		}
	  }
	}
  }
}

