.article-row-section {
  @include flex-grid-row(null, $global-width, 12);
  justify-content: center;
}

.article-row-section-inner {
  @include flex-grid-column(12);

  @include breakpoint(medium) {
	@include flex-grid-column(10);
  }
}

.article-row-section-header {
  padding: 1.5rem 0;
  margin: 0;
  line-height: 1;
}

.article-row {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $light-gray;
  padding: 1.5rem 0;
  border-bottom: 1px solid #eee;

  @include breakpoint(medium) {
	flex-direction: row;
  }

  img{
	width: 200px;
	height: 200px;
  }
}

.article-row-img img {
  width: 100%;

  @include breakpoint(medium) {
	max-width: none;
	width: auto;
  }
}

.article-row-content {
  padding: 1.5rem 0 0;
  color: $body-font-color;

  @include breakpoint(medium) {
	padding: 0 0 0 1.5rem;
  }
}

.article-row-content-header {
  font-size: 1.5rem;
}

.article-row-content-description {
  font-size: 1.25rem;
}

.article-row-content-author,
.article-row-content-time {
  font-size: 0.875rem;
  margin-bottom: 0;
  color: $dark-gray;
}

.article-row-reversed {
  .article-row-content {
	order: 2;
	padding: 0 1.5rem 0 0;
  }

  .article-row-img {
	order: 1;
	padding: 0 0 1.5rem 0;
  }

  @include breakpoint(medium) {
	.article-row-content {
	  order: 1;
	}

	.article-row-img {
	  order: 2;
	}
  }
}

