$card-user-profile-bg: $card-background;
$card-user-profile-text-color: $body-font-color;

.card-user-profile {
  position: relative;
  z-index: 0;
  @include card-container($card-user-profile-bg, $card-user-profile-text-color, 1rem, $global-radius);

  &:hover {
    .card-user-profile-img {
      opacity: 1;
    }
  }
}

.card-user-profile-content {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: rem-calc(-55);
    left: rem-calc(-30);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: rem-calc(55) 0 0 rem-calc(500);
    z-index: 0;
    border-color: transparent transparent transparent $card-user-profile-bg;
  }
}

.card-user-profile-actions {
  @include card-divider;
  background: $card-user-profile-bg;
  display: flex;
}

.card-user-profile-img {
  max-width: 100%;
  opacity: 0.85;
  transition: all 0.25s ease;
}

.card-user-profile-avatar {
  position: absolute;
  bottom: 100%;
  left: 1rem;
  z-index: 2;
  max-width: rem-calc(90);

  img {
    border-radius: 50%;
    box-shadow: 0 0 15px rgba($black, 0.3);
  }
}

.card-user-profile-name {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-user-profile-button {
  margin-bottom: 0;
  flex: 1 0 0;

  + .card-user-profile-button {
    margin-left: 1rem;
  }
}

.card-user-profile-info {
  font-size: 0.875rem;
  letter-spacing: 1px;
  opacity: 0.8;
}

.block-news{
  .card-user-profile-content{
    &::before{ display: none; }
  }
}