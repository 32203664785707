.row{ max-width: 100rem; }

.custom-button{
  padding: 15px 30px;
  @include border-rounded;
  color: white !important;
}

.padding-default{
  padding: 1rem;
}

h2{
  font-size: 2em !important;
  padding: 30px 0 !important;
}

.no-padding{ padding: 0 !important; }

.fs-text{
  font-size: 1.2em !important;
}

.section-default{
  @include breakpoint(small) {
	padding: 20px 0 !important;
  }

  @include breakpoint(medium) {
	padding: 40px 0 !important;
  }

  @include breakpoint(large) {
	padding: 60px 0 !important;
  }

  @include breakpoint(xlarge) {
	padding: 60px 0 !important;
  }
}

.carret-fix{
  a{
    @include breakpoint(small){
      &:after,
      &::after{
        right: 0 !important;
      }
    }
  }
}

.section-default-bg{
  background: #f9f9f9;
  @extend .section-default;
}

.block-icons{
  i{
    font-size: 4em;
  }
}

.custom-tabs{
  .tabs-title{
    a {
      font-size: 1rem !important;

    }
    &.is-active{
      border-bottom: 2px solid orange;
    }
  }
}

.padding-bottom-1{ padding-bottom: 1rem; }
.padding-bottom-2{ padding-bottom: 2rem; }
.padding-bottom-3{ padding-bottom: 3rem; }

.padding-top-1{ padding-top: 1rem; }
.padding-top-2{ padding-top: 2rem; }
.padding-top-3{ padding-top: 3rem; }

.padding-1{ padding: 1rem; }
.padding-2{ padding: 2rem; }
.padding-3{ padding: 3rem; }

.margin-bottom-1{ margin-bottom: 1rem; }
.margin-bottom-2{ margin-bottom: 2rem; }
.margin-bottom-3{ margin-bottom: 3rem; }

.margin-top-1{ margin-top: 1rem; }
.margin-top-2{ margin-top: 2rem; }
.margin-top-3{ margin-top: 3rem; }

.margin-1{ margin: 1rem; }
.margin-2{ margin: 2rem; }
.margin-3{ margin: 3rem; }

.page-bg{
  padding: 10rem 0 !important;
  color: white;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  background-position: center center;

  h1{
    line-height: 3rem;
    padding: 0;
    margin-bottom: 0;
    font-size: 4rem;
  }
}

.fs-1{ font-size: 1rem !important; }
.fs-2{ font-size: 2rem !important; }
.fs-3{ font-size: 3rem !important; }
.fs-4{ font-size: 4rem !important; }
.fs-5{ font-size: 5rem !important; }

.success{
  background: mediumseagreen !important;
  color: white !important;
}